/**
 * @fileoverview gRPC-Web generated client stub for deal
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/deal/deal.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_deal_deal_pb from '../../protobuf/deal/deal_pb';


export class DealServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorMerge = new grpcWeb.MethodDescriptor(
    '/deal.DealService/Merge',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.MergeDealsRequest,
    protobuf_deal_deal_pb.MergeDealsResponse,
    (request: protobuf_deal_deal_pb.MergeDealsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.MergeDealsResponse.deserializeBinary
  );

  merge(
    request: protobuf_deal_deal_pb.MergeDealsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.MergeDealsResponse>;

  merge(
    request: protobuf_deal_deal_pb.MergeDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.MergeDealsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.MergeDealsResponse>;

  merge(
    request: protobuf_deal_deal_pb.MergeDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.MergeDealsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/Merge',
        request,
        metadata || {},
        this.methodDescriptorMerge,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/Merge',
    request,
    metadata || {},
    this.methodDescriptorMerge);
  }

  methodDescriptorUnmerge = new grpcWeb.MethodDescriptor(
    '/deal.DealService/Unmerge',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.UnmergeDealRequest,
    protobuf_deal_deal_pb.UnmergeDealResponse,
    (request: protobuf_deal_deal_pb.UnmergeDealRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.UnmergeDealResponse.deserializeBinary
  );

  unmerge(
    request: protobuf_deal_deal_pb.UnmergeDealRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.UnmergeDealResponse>;

  unmerge(
    request: protobuf_deal_deal_pb.UnmergeDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.UnmergeDealResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.UnmergeDealResponse>;

  unmerge(
    request: protobuf_deal_deal_pb.UnmergeDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.UnmergeDealResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/Unmerge',
        request,
        metadata || {},
        this.methodDescriptorUnmerge,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/Unmerge',
    request,
    metadata || {},
    this.methodDescriptorUnmerge);
  }

  methodDescriptorMove = new grpcWeb.MethodDescriptor(
    '/deal.DealService/Move',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.MoveDealRequest,
    protobuf_deal_deal_pb.MoveDealResponse,
    (request: protobuf_deal_deal_pb.MoveDealRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.MoveDealResponse.deserializeBinary
  );

  move(
    request: protobuf_deal_deal_pb.MoveDealRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.MoveDealResponse>;

  move(
    request: protobuf_deal_deal_pb.MoveDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.MoveDealResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.MoveDealResponse>;

  move(
    request: protobuf_deal_deal_pb.MoveDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.MoveDealResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/Move',
        request,
        metadata || {},
        this.methodDescriptorMove,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/Move',
    request,
    metadata || {},
    this.methodDescriptorMove);
  }

  methodDescriptorMoveOrder = new grpcWeb.MethodDescriptor(
    '/deal.DealService/MoveOrder',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.MoveDealOrderRequest,
    protobuf_deal_deal_pb.MoveDealOrderResponse,
    (request: protobuf_deal_deal_pb.MoveDealOrderRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.MoveDealOrderResponse.deserializeBinary
  );

  moveOrder(
    request: protobuf_deal_deal_pb.MoveDealOrderRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.MoveDealOrderResponse>;

  moveOrder(
    request: protobuf_deal_deal_pb.MoveDealOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.MoveDealOrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.MoveDealOrderResponse>;

  moveOrder(
    request: protobuf_deal_deal_pb.MoveDealOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.MoveDealOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/MoveOrder',
        request,
        metadata || {},
        this.methodDescriptorMoveOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/MoveOrder',
    request,
    metadata || {},
    this.methodDescriptorMoveOrder);
  }

  methodDescriptorAssign = new grpcWeb.MethodDescriptor(
    '/deal.DealService/Assign',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.AssignDealRequest,
    protobuf_deal_deal_pb.AssignDealResponse,
    (request: protobuf_deal_deal_pb.AssignDealRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.AssignDealResponse.deserializeBinary
  );

  assign(
    request: protobuf_deal_deal_pb.AssignDealRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.AssignDealResponse>;

  assign(
    request: protobuf_deal_deal_pb.AssignDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.AssignDealResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.AssignDealResponse>;

  assign(
    request: protobuf_deal_deal_pb.AssignDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.AssignDealResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/Assign',
        request,
        metadata || {},
        this.methodDescriptorAssign,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/Assign',
    request,
    metadata || {},
    this.methodDescriptorAssign);
  }

  methodDescriptorAssignDispatcher = new grpcWeb.MethodDescriptor(
    '/deal.DealService/AssignDispatcher',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.AssignDispatcherDealRequest,
    protobuf_deal_deal_pb.AssignDispatcherDealResponse,
    (request: protobuf_deal_deal_pb.AssignDispatcherDealRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.AssignDispatcherDealResponse.deserializeBinary
  );

  assignDispatcher(
    request: protobuf_deal_deal_pb.AssignDispatcherDealRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.AssignDispatcherDealResponse>;

  assignDispatcher(
    request: protobuf_deal_deal_pb.AssignDispatcherDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.AssignDispatcherDealResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.AssignDispatcherDealResponse>;

  assignDispatcher(
    request: protobuf_deal_deal_pb.AssignDispatcherDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.AssignDispatcherDealResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/AssignDispatcher',
        request,
        metadata || {},
        this.methodDescriptorAssignDispatcher,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/AssignDispatcher',
    request,
    metadata || {},
    this.methodDescriptorAssignDispatcher);
  }

  methodDescriptorAssignBroker = new grpcWeb.MethodDescriptor(
    '/deal.DealService/AssignBroker',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.AssignBrokerDealRequest,
    protobuf_deal_deal_pb.AssignBrokerDealResponse,
    (request: protobuf_deal_deal_pb.AssignBrokerDealRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.AssignBrokerDealResponse.deserializeBinary
  );

  assignBroker(
    request: protobuf_deal_deal_pb.AssignBrokerDealRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.AssignBrokerDealResponse>;

  assignBroker(
    request: protobuf_deal_deal_pb.AssignBrokerDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.AssignBrokerDealResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.AssignBrokerDealResponse>;

  assignBroker(
    request: protobuf_deal_deal_pb.AssignBrokerDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.AssignBrokerDealResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/AssignBroker',
        request,
        metadata || {},
        this.methodDescriptorAssignBroker,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/AssignBroker',
    request,
    metadata || {},
    this.methodDescriptorAssignBroker);
  }

  methodDescriptorSetLoadStatus = new grpcWeb.MethodDescriptor(
    '/deal.DealService/SetLoadStatus',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.SetLoadStatusDealRequest,
    protobuf_deal_deal_pb.SetLoadStatusDealResponse,
    (request: protobuf_deal_deal_pb.SetLoadStatusDealRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.SetLoadStatusDealResponse.deserializeBinary
  );

  setLoadStatus(
    request: protobuf_deal_deal_pb.SetLoadStatusDealRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.SetLoadStatusDealResponse>;

  setLoadStatus(
    request: protobuf_deal_deal_pb.SetLoadStatusDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.SetLoadStatusDealResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.SetLoadStatusDealResponse>;

  setLoadStatus(
    request: protobuf_deal_deal_pb.SetLoadStatusDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.SetLoadStatusDealResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/SetLoadStatus',
        request,
        metadata || {},
        this.methodDescriptorSetLoadStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/SetLoadStatus',
    request,
    metadata || {},
    this.methodDescriptorSetLoadStatus);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/deal.DealService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.GetDealsRequest,
    protobuf_deal_deal_pb.GetDealsResponse,
    (request: protobuf_deal_deal_pb.GetDealsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.GetDealsResponse.deserializeBinary
  );

  get(
    request: protobuf_deal_deal_pb.GetDealsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.GetDealsResponse>;

  get(
    request: protobuf_deal_deal_pb.GetDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.GetDealsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.GetDealsResponse>;

  get(
    request: protobuf_deal_deal_pb.GetDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.GetDealsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/deal.DealService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.ListDealsRequest,
    protobuf_deal_deal_pb.ListDealsResponse,
    (request: protobuf_deal_deal_pb.ListDealsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.ListDealsResponse.deserializeBinary
  );

  list(
    request: protobuf_deal_deal_pb.ListDealsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.ListDealsResponse>;

  list(
    request: protobuf_deal_deal_pb.ListDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.ListDealsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.ListDealsResponse>;

  list(
    request: protobuf_deal_deal_pb.ListDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.ListDealsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorListDocuments = new grpcWeb.MethodDescriptor(
    '/deal.DealService/ListDocuments',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.ListDocumentsRequest,
    protobuf_deal_deal_pb.ListDocumentsResponse,
    (request: protobuf_deal_deal_pb.ListDocumentsRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.ListDocumentsResponse.deserializeBinary
  );

  listDocuments(
    request: protobuf_deal_deal_pb.ListDocumentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.ListDocumentsResponse>;

  listDocuments(
    request: protobuf_deal_deal_pb.ListDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.ListDocumentsResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.ListDocumentsResponse>;

  listDocuments(
    request: protobuf_deal_deal_pb.ListDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.ListDocumentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/ListDocuments',
        request,
        metadata || {},
        this.methodDescriptorListDocuments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/ListDocuments',
    request,
    metadata || {},
    this.methodDescriptorListDocuments);
  }

  methodDescriptorGetRoute = new grpcWeb.MethodDescriptor(
    '/deal.DealService/GetRoute',
    grpcWeb.MethodType.UNARY,
    protobuf_deal_deal_pb.GetRouteRequest,
    protobuf_deal_deal_pb.GetRouteResponse,
    (request: protobuf_deal_deal_pb.GetRouteRequest) => {
      return request.serializeBinary();
    },
    protobuf_deal_deal_pb.GetRouteResponse.deserializeBinary
  );

  getRoute(
    request: protobuf_deal_deal_pb.GetRouteRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_deal_deal_pb.GetRouteResponse>;

  getRoute(
    request: protobuf_deal_deal_pb.GetRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.GetRouteResponse) => void): grpcWeb.ClientReadableStream<protobuf_deal_deal_pb.GetRouteResponse>;

  getRoute(
    request: protobuf_deal_deal_pb.GetRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_deal_deal_pb.GetRouteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deal.DealService/GetRoute',
        request,
        metadata || {},
        this.methodDescriptorGetRoute,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deal.DealService/GetRoute',
    request,
    metadata || {},
    this.methodDescriptorGetRoute);
  }

}

