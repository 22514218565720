// source: protobuf/deal/deal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.deal.AssignBrokerDealRequest', null, global);
goog.exportSymbol('proto.deal.AssignBrokerDealResponse', null, global);
goog.exportSymbol('proto.deal.AssignDealRequest', null, global);
goog.exportSymbol('proto.deal.AssignDealResponse', null, global);
goog.exportSymbol('proto.deal.AssignDispatcherDealRequest', null, global);
goog.exportSymbol('proto.deal.AssignDispatcherDealResponse', null, global);
goog.exportSymbol('proto.deal.GetDealsRequest', null, global);
goog.exportSymbol('proto.deal.GetDealsResponse', null, global);
goog.exportSymbol('proto.deal.GetRouteRequest', null, global);
goog.exportSymbol('proto.deal.GetRouteResponse', null, global);
goog.exportSymbol('proto.deal.ListDealsRequest', null, global);
goog.exportSymbol('proto.deal.ListDealsResponse', null, global);
goog.exportSymbol('proto.deal.ListDocumentsDocTypesByCargoItemResponse', null, global);
goog.exportSymbol('proto.deal.ListDocumentsRequest', null, global);
goog.exportSymbol('proto.deal.ListDocumentsResponse', null, global);
goog.exportSymbol('proto.deal.MergeDealsRequest', null, global);
goog.exportSymbol('proto.deal.MergeDealsResponse', null, global);
goog.exportSymbol('proto.deal.MoveDealOrderRequest', null, global);
goog.exportSymbol('proto.deal.MoveDealOrderResponse', null, global);
goog.exportSymbol('proto.deal.MoveDealRequest', null, global);
goog.exportSymbol('proto.deal.MoveDealResponse', null, global);
goog.exportSymbol('proto.deal.OrderDocTypes', null, global);
goog.exportSymbol('proto.deal.RouteCityGroup', null, global);
goog.exportSymbol('proto.deal.RouteItem', null, global);
goog.exportSymbol('proto.deal.RouteResponse', null, global);
goog.exportSymbol('proto.deal.SetLoadStatusDealRequest', null, global);
goog.exportSymbol('proto.deal.SetLoadStatusDealResponse', null, global);
goog.exportSymbol('proto.deal.UnmergeDealRequest', null, global);
goog.exportSymbol('proto.deal.UnmergeDealResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.ListDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.ListDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.ListDocumentsRequest.displayName = 'proto.deal.ListDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.ListDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.ListDocumentsResponse.repeatedFields_, null);
};
goog.inherits(proto.deal.ListDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.ListDocumentsResponse.displayName = 'proto.deal.ListDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.ListDocumentsDocTypesByCargoItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.ListDocumentsDocTypesByCargoItemResponse.displayName = 'proto.deal.ListDocumentsDocTypesByCargoItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.OrderDocTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.OrderDocTypes.repeatedFields_, null);
};
goog.inherits(proto.deal.OrderDocTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.OrderDocTypes.displayName = 'proto.deal.OrderDocTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.ListDealsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.ListDealsRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.ListDealsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.ListDealsRequest.displayName = 'proto.deal.ListDealsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.ListDealsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.ListDealsResponse.repeatedFields_, null);
};
goog.inherits(proto.deal.ListDealsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.ListDealsResponse.displayName = 'proto.deal.ListDealsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.MergeDealsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.MergeDealsRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.MergeDealsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.MergeDealsRequest.displayName = 'proto.deal.MergeDealsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.MergeDealsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.MergeDealsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.MergeDealsResponse.displayName = 'proto.deal.MergeDealsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.UnmergeDealRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.UnmergeDealRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.UnmergeDealRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.UnmergeDealRequest.displayName = 'proto.deal.UnmergeDealRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.UnmergeDealResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.UnmergeDealResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.UnmergeDealResponse.displayName = 'proto.deal.UnmergeDealResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.MoveDealRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.MoveDealRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.MoveDealRequest.displayName = 'proto.deal.MoveDealRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.MoveDealResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.MoveDealResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.MoveDealResponse.displayName = 'proto.deal.MoveDealResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.MoveDealOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.MoveDealOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.MoveDealOrderRequest.displayName = 'proto.deal.MoveDealOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.MoveDealOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.MoveDealOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.MoveDealOrderResponse.displayName = 'proto.deal.MoveDealOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.AssignDealRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.AssignDealRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.AssignDealRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.AssignDealRequest.displayName = 'proto.deal.AssignDealRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.AssignDealResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.AssignDealResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.AssignDealResponse.displayName = 'proto.deal.AssignDealResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.AssignBrokerDealRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.AssignBrokerDealRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.AssignBrokerDealRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.AssignBrokerDealRequest.displayName = 'proto.deal.AssignBrokerDealRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.AssignBrokerDealResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.AssignBrokerDealResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.AssignBrokerDealResponse.displayName = 'proto.deal.AssignBrokerDealResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.AssignDispatcherDealRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.AssignDispatcherDealRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.AssignDispatcherDealRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.AssignDispatcherDealRequest.displayName = 'proto.deal.AssignDispatcherDealRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.AssignDispatcherDealResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.AssignDispatcherDealResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.AssignDispatcherDealResponse.displayName = 'proto.deal.AssignDispatcherDealResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.SetLoadStatusDealRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.SetLoadStatusDealRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.SetLoadStatusDealRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.SetLoadStatusDealRequest.displayName = 'proto.deal.SetLoadStatusDealRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.SetLoadStatusDealResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.SetLoadStatusDealResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.SetLoadStatusDealResponse.displayName = 'proto.deal.SetLoadStatusDealResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.GetDealsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.GetDealsRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.GetDealsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.GetDealsRequest.displayName = 'proto.deal.GetDealsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.GetDealsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.GetDealsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.GetDealsResponse.displayName = 'proto.deal.GetDealsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.GetRouteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.GetRouteRequest.repeatedFields_, null);
};
goog.inherits(proto.deal.GetRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.GetRouteRequest.displayName = 'proto.deal.GetRouteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.GetRouteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.GetRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.GetRouteResponse.displayName = 'proto.deal.GetRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.RouteCityGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.RouteCityGroup.repeatedFields_, null);
};
goog.inherits(proto.deal.RouteCityGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.RouteCityGroup.displayName = 'proto.deal.RouteCityGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.RouteItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.deal.RouteItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.RouteItem.displayName = 'proto.deal.RouteItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.deal.RouteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.deal.RouteResponse.repeatedFields_, null);
};
goog.inherits(proto.deal.RouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.deal.RouteResponse.displayName = 'proto.deal.RouteResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.ListDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.ListDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.ListDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.ListDocumentsRequest}
 */
proto.deal.ListDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.ListDocumentsRequest;
  return proto.deal.ListDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.ListDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.ListDocumentsRequest}
 */
proto.deal.ListDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.ListDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.ListDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.ListDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.deal.ListDocumentsRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.ListDocumentsRequest} returns this
 */
proto.deal.ListDocumentsRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.ListDocumentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.ListDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.ListDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.ListDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    protobuf_model_merged_pb.DealListDocumentResponse.toObject, includeInstance),
    ordersCargoItemsMap: (f = msg.getOrdersCargoItemsMap()) ? f.toObject(includeInstance, proto.deal.ListDocumentsDocTypesByCargoItemResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.ListDocumentsResponse}
 */
proto.deal.ListDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.ListDocumentsResponse;
  return proto.deal.ListDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.ListDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.ListDocumentsResponse}
 */
proto.deal.ListDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealListDocumentResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealListDocumentResponse.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 2:
      var value = msg.getOrdersCargoItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.deal.ListDocumentsDocTypesByCargoItemResponse.deserializeBinaryFromReader, "", new proto.deal.ListDocumentsDocTypesByCargoItemResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.ListDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.ListDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.ListDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      protobuf_model_merged_pb.DealListDocumentResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrdersCargoItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.deal.ListDocumentsDocTypesByCargoItemResponse.serializeBinaryToWriter);
  }
};


/**
 * repeated model.DealListDocumentResponse documents = 1;
 * @return {!Array<!proto.model.DealListDocumentResponse>}
 */
proto.deal.ListDocumentsResponse.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.model.DealListDocumentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.DealListDocumentResponse, 1));
};


/**
 * @param {!Array<!proto.model.DealListDocumentResponse>} value
 * @return {!proto.deal.ListDocumentsResponse} returns this
*/
proto.deal.ListDocumentsResponse.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.DealListDocumentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DealListDocumentResponse}
 */
proto.deal.ListDocumentsResponse.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.DealListDocumentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.ListDocumentsResponse} returns this
 */
proto.deal.ListDocumentsResponse.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * map<string, ListDocumentsDocTypesByCargoItemResponse> orders_cargo_items = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.deal.ListDocumentsDocTypesByCargoItemResponse>}
 */
proto.deal.ListDocumentsResponse.prototype.getOrdersCargoItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.deal.ListDocumentsDocTypesByCargoItemResponse>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.deal.ListDocumentsDocTypesByCargoItemResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.deal.ListDocumentsResponse} returns this
 */
proto.deal.ListDocumentsResponse.prototype.clearOrdersCargoItemsMap = function() {
  this.getOrdersCargoItemsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.ListDocumentsDocTypesByCargoItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.ListDocumentsDocTypesByCargoItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersDocTypesMap: (f = msg.getOrdersDocTypesMap()) ? f.toObject(includeInstance, proto.deal.OrderDocTypes.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.ListDocumentsDocTypesByCargoItemResponse}
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.ListDocumentsDocTypesByCargoItemResponse;
  return proto.deal.ListDocumentsDocTypesByCargoItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.ListDocumentsDocTypesByCargoItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.ListDocumentsDocTypesByCargoItemResponse}
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getOrdersDocTypesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.deal.OrderDocTypes.deserializeBinaryFromReader, "", new proto.deal.OrderDocTypes());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.ListDocumentsDocTypesByCargoItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.ListDocumentsDocTypesByCargoItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersDocTypesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.deal.OrderDocTypes.serializeBinaryToWriter);
  }
};


/**
 * map<string, OrderDocTypes> orders_doc_types = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.deal.OrderDocTypes>}
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.prototype.getOrdersDocTypesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.deal.OrderDocTypes>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.deal.OrderDocTypes));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.deal.ListDocumentsDocTypesByCargoItemResponse} returns this
 */
proto.deal.ListDocumentsDocTypesByCargoItemResponse.prototype.clearOrdersDocTypesMap = function() {
  this.getOrdersDocTypesMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.OrderDocTypes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.OrderDocTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.OrderDocTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.OrderDocTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.OrderDocTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    docTypesList: jspb.Message.toObjectList(msg.getDocTypesList(),
    protobuf_model_merged_pb.DocTypeResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.OrderDocTypes}
 */
proto.deal.OrderDocTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.OrderDocTypes;
  return proto.deal.OrderDocTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.OrderDocTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.OrderDocTypes}
 */
proto.deal.OrderDocTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DocTypeResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DocTypeResponse.deserializeBinaryFromReader);
      msg.addDocTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.OrderDocTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.OrderDocTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.OrderDocTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.OrderDocTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      protobuf_model_merged_pb.DocTypeResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated model.DocTypeResponse doc_types = 1;
 * @return {!Array<!proto.model.DocTypeResponse>}
 */
proto.deal.OrderDocTypes.prototype.getDocTypesList = function() {
  return /** @type{!Array<!proto.model.DocTypeResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.DocTypeResponse, 1));
};


/**
 * @param {!Array<!proto.model.DocTypeResponse>} value
 * @return {!proto.deal.OrderDocTypes} returns this
*/
proto.deal.OrderDocTypes.prototype.setDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.DocTypeResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DocTypeResponse}
 */
proto.deal.OrderDocTypes.prototype.addDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.DocTypeResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.OrderDocTypes} returns this
 */
proto.deal.OrderDocTypes.prototype.clearDocTypesList = function() {
  return this.setDocTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.ListDealsRequest.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.ListDealsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.ListDealsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.ListDealsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDealsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f),
    globalSearch: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cargoTypeIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    categoryIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    trailerTypeIdList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.ListDealsRequest}
 */
proto.deal.ListDealsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.ListDealsRequest;
  return proto.deal.ListDealsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.ListDealsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.ListDealsRequest}
 */
proto.deal.ListDealsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalSearch(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCargoTypeId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrailerTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.ListDealsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.ListDealsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.ListDealsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDealsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
  f = message.getGlobalSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCargoTypeIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getCategoryIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getTrailerTypeIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.deal.ListDealsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.deal.ListDealsRequest} returns this
*/
proto.deal.ListDealsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.ListDealsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string global_search = 2;
 * @return {string}
 */
proto.deal.ListDealsRequest.prototype.getGlobalSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.setGlobalSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_by = 3;
 * @return {string}
 */
proto.deal.ListDealsRequest.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string cargo_type_id = 4;
 * @return {!Array<string>}
 */
proto.deal.ListDealsRequest.prototype.getCargoTypeIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.setCargoTypeIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.addCargoTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.clearCargoTypeIdList = function() {
  return this.setCargoTypeIdList([]);
};


/**
 * repeated string category_id = 5;
 * @return {!Array<string>}
 */
proto.deal.ListDealsRequest.prototype.getCategoryIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.setCategoryIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.addCategoryId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.clearCategoryIdList = function() {
  return this.setCategoryIdList([]);
};


/**
 * repeated string trailer_type_id = 6;
 * @return {!Array<string>}
 */
proto.deal.ListDealsRequest.prototype.getTrailerTypeIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.setTrailerTypeIdList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.addTrailerTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.ListDealsRequest} returns this
 */
proto.deal.ListDealsRequest.prototype.clearTrailerTypeIdList = function() {
  return this.setTrailerTypeIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.ListDealsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.ListDealsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.ListDealsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.ListDealsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDealsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.DealResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.ListDealsResponse}
 */
proto.deal.ListDealsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.ListDealsResponse;
  return proto.deal.ListDealsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.ListDealsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.ListDealsResponse}
 */
proto.deal.ListDealsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.ListDealsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.ListDealsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.ListDealsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.ListDealsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.deal.ListDealsResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.deal.ListDealsResponse} returns this
*/
proto.deal.ListDealsResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.ListDealsResponse} returns this
 */
proto.deal.ListDealsResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.ListDealsResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.DealResponse items = 2;
 * @return {!Array<!proto.model.DealResponse>}
 */
proto.deal.ListDealsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.DealResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.DealResponse, 2));
};


/**
 * @param {!Array<!proto.model.DealResponse>} value
 * @return {!proto.deal.ListDealsResponse} returns this
*/
proto.deal.ListDealsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.DealResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DealResponse}
 */
proto.deal.ListDealsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.DealResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.ListDealsResponse} returns this
 */
proto.deal.ListDealsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.MergeDealsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.MergeDealsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.MergeDealsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.MergeDealsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MergeDealsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.MergeDealsRequest}
 */
proto.deal.MergeDealsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.MergeDealsRequest;
  return proto.deal.MergeDealsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.MergeDealsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.MergeDealsRequest}
 */
proto.deal.MergeDealsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.MergeDealsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.MergeDealsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.MergeDealsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MergeDealsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal.MergeDealsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.MergeDealsRequest} returns this
 */
proto.deal.MergeDealsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.MergeDealsRequest} returns this
 */
proto.deal.MergeDealsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.MergeDealsRequest} returns this
 */
proto.deal.MergeDealsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.MergeDealsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.MergeDealsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.MergeDealsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MergeDealsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deal: (f = msg.getDeal()) && protobuf_model_merged_pb.DealResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.MergeDealsResponse}
 */
proto.deal.MergeDealsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.MergeDealsResponse;
  return proto.deal.MergeDealsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.MergeDealsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.MergeDealsResponse}
 */
proto.deal.MergeDealsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.MergeDealsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.MergeDealsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.MergeDealsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MergeDealsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional model.DealResponse deal = 1;
 * @return {?proto.model.DealResponse}
 */
proto.deal.MergeDealsResponse.prototype.getDeal = function() {
  return /** @type{?proto.model.DealResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DealResponse, 1));
};


/**
 * @param {?proto.model.DealResponse|undefined} value
 * @return {!proto.deal.MergeDealsResponse} returns this
*/
proto.deal.MergeDealsResponse.prototype.setDeal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.MergeDealsResponse} returns this
 */
proto.deal.MergeDealsResponse.prototype.clearDeal = function() {
  return this.setDeal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.MergeDealsResponse.prototype.hasDeal = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.UnmergeDealRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.UnmergeDealRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.UnmergeDealRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.UnmergeDealRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.UnmergeDealRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    removeOrderIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.UnmergeDealRequest}
 */
proto.deal.UnmergeDealRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.UnmergeDealRequest;
  return proto.deal.UnmergeDealRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.UnmergeDealRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.UnmergeDealRequest}
 */
proto.deal.UnmergeDealRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRemoveOrderIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.UnmergeDealRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.UnmergeDealRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.UnmergeDealRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.UnmergeDealRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRemoveOrderIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.deal.UnmergeDealRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.UnmergeDealRequest} returns this
 */
proto.deal.UnmergeDealRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string remove_order_ids = 2;
 * @return {!Array<string>}
 */
proto.deal.UnmergeDealRequest.prototype.getRemoveOrderIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.UnmergeDealRequest} returns this
 */
proto.deal.UnmergeDealRequest.prototype.setRemoveOrderIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.UnmergeDealRequest} returns this
 */
proto.deal.UnmergeDealRequest.prototype.addRemoveOrderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.UnmergeDealRequest} returns this
 */
proto.deal.UnmergeDealRequest.prototype.clearRemoveOrderIdsList = function() {
  return this.setRemoveOrderIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.UnmergeDealResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.UnmergeDealResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.UnmergeDealResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.UnmergeDealResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.UnmergeDealResponse}
 */
proto.deal.UnmergeDealResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.UnmergeDealResponse;
  return proto.deal.UnmergeDealResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.UnmergeDealResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.UnmergeDealResponse}
 */
proto.deal.UnmergeDealResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.UnmergeDealResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.UnmergeDealResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.UnmergeDealResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.UnmergeDealResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.MoveDealRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.MoveDealRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.MoveDealRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    afterId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transferComment: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.MoveDealRequest}
 */
proto.deal.MoveDealRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.MoveDealRequest;
  return proto.deal.MoveDealRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.MoveDealRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.MoveDealRequest}
 */
proto.deal.MoveDealRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.model.DealStatus} */ (reader.readEnum());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.MoveDealRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.MoveDealRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.MoveDealRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAfterId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransferComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.deal.MoveDealRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.MoveDealRequest} returns this
 */
proto.deal.MoveDealRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional model.DealStatus to = 2;
 * @return {!proto.model.DealStatus}
 */
proto.deal.MoveDealRequest.prototype.getTo = function() {
  return /** @type {!proto.model.DealStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.DealStatus} value
 * @return {!proto.deal.MoveDealRequest} returns this
 */
proto.deal.MoveDealRequest.prototype.setTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string after_id = 3;
 * @return {string}
 */
proto.deal.MoveDealRequest.prototype.getAfterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.MoveDealRequest} returns this
 */
proto.deal.MoveDealRequest.prototype.setAfterId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transfer_comment = 4;
 * @return {string}
 */
proto.deal.MoveDealRequest.prototype.getTransferComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.MoveDealRequest} returns this
 */
proto.deal.MoveDealRequest.prototype.setTransferComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.MoveDealResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.MoveDealResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.MoveDealResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deal: (f = msg.getDeal()) && protobuf_model_merged_pb.DealResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.MoveDealResponse}
 */
proto.deal.MoveDealResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.MoveDealResponse;
  return proto.deal.MoveDealResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.MoveDealResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.MoveDealResponse}
 */
proto.deal.MoveDealResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.MoveDealResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.MoveDealResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.MoveDealResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional model.DealResponse deal = 1;
 * @return {?proto.model.DealResponse}
 */
proto.deal.MoveDealResponse.prototype.getDeal = function() {
  return /** @type{?proto.model.DealResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DealResponse, 1));
};


/**
 * @param {?proto.model.DealResponse|undefined} value
 * @return {!proto.deal.MoveDealResponse} returns this
*/
proto.deal.MoveDealResponse.prototype.setDeal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.MoveDealResponse} returns this
 */
proto.deal.MoveDealResponse.prototype.clearDeal = function() {
  return this.setDeal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.MoveDealResponse.prototype.hasDeal = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.MoveDealOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.MoveDealOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.MoveDealOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    afterOrderId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.MoveDealOrderRequest}
 */
proto.deal.MoveDealOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.MoveDealOrderRequest;
  return proto.deal.MoveDealOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.MoveDealOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.MoveDealOrderRequest}
 */
proto.deal.MoveDealOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.MoveDealOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.MoveDealOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.MoveDealOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAfterOrderId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.deal.MoveDealOrderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.MoveDealOrderRequest} returns this
 */
proto.deal.MoveDealOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.deal.MoveDealOrderRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.MoveDealOrderRequest} returns this
 */
proto.deal.MoveDealOrderRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string after_order_id = 3;
 * @return {string}
 */
proto.deal.MoveDealOrderRequest.prototype.getAfterOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.MoveDealOrderRequest} returns this
 */
proto.deal.MoveDealOrderRequest.prototype.setAfterOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.MoveDealOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.MoveDealOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.MoveDealOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deal: (f = msg.getDeal()) && protobuf_model_merged_pb.DealResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.MoveDealOrderResponse}
 */
proto.deal.MoveDealOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.MoveDealOrderResponse;
  return proto.deal.MoveDealOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.MoveDealOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.MoveDealOrderResponse}
 */
proto.deal.MoveDealOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.MoveDealOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.MoveDealOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.MoveDealOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.MoveDealOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional model.DealResponse deal = 1;
 * @return {?proto.model.DealResponse}
 */
proto.deal.MoveDealOrderResponse.prototype.getDeal = function() {
  return /** @type{?proto.model.DealResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DealResponse, 1));
};


/**
 * @param {?proto.model.DealResponse|undefined} value
 * @return {!proto.deal.MoveDealOrderResponse} returns this
*/
proto.deal.MoveDealOrderResponse.prototype.setDeal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.MoveDealOrderResponse} returns this
 */
proto.deal.MoveDealOrderResponse.prototype.clearDeal = function() {
  return this.setDeal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.MoveDealOrderResponse.prototype.hasDeal = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.AssignDealRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.AssignDealRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.AssignDealRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.AssignDealRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDealRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.AssignDealRequest}
 */
proto.deal.AssignDealRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.AssignDealRequest;
  return proto.deal.AssignDealRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.AssignDealRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.AssignDealRequest}
 */
proto.deal.AssignDealRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.AssignDealRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.AssignDealRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.AssignDealRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDealRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal.AssignDealRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.AssignDealRequest} returns this
 */
proto.deal.AssignDealRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.AssignDealRequest} returns this
 */
proto.deal.AssignDealRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.AssignDealRequest} returns this
 */
proto.deal.AssignDealRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.deal.AssignDealRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.AssignDealRequest} returns this
 */
proto.deal.AssignDealRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.AssignDealResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.AssignDealResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.AssignDealResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDealResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deal: (f = msg.getDeal()) && protobuf_model_merged_pb.DealResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.AssignDealResponse}
 */
proto.deal.AssignDealResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.AssignDealResponse;
  return proto.deal.AssignDealResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.AssignDealResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.AssignDealResponse}
 */
proto.deal.AssignDealResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.AssignDealResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.AssignDealResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.AssignDealResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDealResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional model.DealResponse deal = 1;
 * @return {?proto.model.DealResponse}
 */
proto.deal.AssignDealResponse.prototype.getDeal = function() {
  return /** @type{?proto.model.DealResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DealResponse, 1));
};


/**
 * @param {?proto.model.DealResponse|undefined} value
 * @return {!proto.deal.AssignDealResponse} returns this
*/
proto.deal.AssignDealResponse.prototype.setDeal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.AssignDealResponse} returns this
 */
proto.deal.AssignDealResponse.prototype.clearDeal = function() {
  return this.setDeal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.AssignDealResponse.prototype.hasDeal = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.AssignBrokerDealRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.AssignBrokerDealRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.AssignBrokerDealRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.AssignBrokerDealRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignBrokerDealRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.AssignBrokerDealRequest}
 */
proto.deal.AssignBrokerDealRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.AssignBrokerDealRequest;
  return proto.deal.AssignBrokerDealRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.AssignBrokerDealRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.AssignBrokerDealRequest}
 */
proto.deal.AssignBrokerDealRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.AssignBrokerDealRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.AssignBrokerDealRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.AssignBrokerDealRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignBrokerDealRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal.AssignBrokerDealRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.AssignBrokerDealRequest} returns this
 */
proto.deal.AssignBrokerDealRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.AssignBrokerDealRequest} returns this
 */
proto.deal.AssignBrokerDealRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.AssignBrokerDealRequest} returns this
 */
proto.deal.AssignBrokerDealRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.deal.AssignBrokerDealRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.AssignBrokerDealRequest} returns this
 */
proto.deal.AssignBrokerDealRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.AssignBrokerDealResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.AssignBrokerDealResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.AssignBrokerDealResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignBrokerDealResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deal: (f = msg.getDeal()) && protobuf_model_merged_pb.DealResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.AssignBrokerDealResponse}
 */
proto.deal.AssignBrokerDealResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.AssignBrokerDealResponse;
  return proto.deal.AssignBrokerDealResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.AssignBrokerDealResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.AssignBrokerDealResponse}
 */
proto.deal.AssignBrokerDealResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.AssignBrokerDealResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.AssignBrokerDealResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.AssignBrokerDealResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignBrokerDealResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional model.DealResponse deal = 1;
 * @return {?proto.model.DealResponse}
 */
proto.deal.AssignBrokerDealResponse.prototype.getDeal = function() {
  return /** @type{?proto.model.DealResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DealResponse, 1));
};


/**
 * @param {?proto.model.DealResponse|undefined} value
 * @return {!proto.deal.AssignBrokerDealResponse} returns this
*/
proto.deal.AssignBrokerDealResponse.prototype.setDeal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.AssignBrokerDealResponse} returns this
 */
proto.deal.AssignBrokerDealResponse.prototype.clearDeal = function() {
  return this.setDeal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.AssignBrokerDealResponse.prototype.hasDeal = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.AssignDispatcherDealRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.AssignDispatcherDealRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.AssignDispatcherDealRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.AssignDispatcherDealRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDispatcherDealRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.AssignDispatcherDealRequest}
 */
proto.deal.AssignDispatcherDealRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.AssignDispatcherDealRequest;
  return proto.deal.AssignDispatcherDealRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.AssignDispatcherDealRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.AssignDispatcherDealRequest}
 */
proto.deal.AssignDispatcherDealRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.AssignDispatcherDealRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.AssignDispatcherDealRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.AssignDispatcherDealRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDispatcherDealRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal.AssignDispatcherDealRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.AssignDispatcherDealRequest} returns this
 */
proto.deal.AssignDispatcherDealRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.AssignDispatcherDealRequest} returns this
 */
proto.deal.AssignDispatcherDealRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.AssignDispatcherDealRequest} returns this
 */
proto.deal.AssignDispatcherDealRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.deal.AssignDispatcherDealRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.AssignDispatcherDealRequest} returns this
 */
proto.deal.AssignDispatcherDealRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.AssignDispatcherDealResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.AssignDispatcherDealResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.AssignDispatcherDealResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDispatcherDealResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deal: (f = msg.getDeal()) && protobuf_model_merged_pb.DealResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.AssignDispatcherDealResponse}
 */
proto.deal.AssignDispatcherDealResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.AssignDispatcherDealResponse;
  return proto.deal.AssignDispatcherDealResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.AssignDispatcherDealResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.AssignDispatcherDealResponse}
 */
proto.deal.AssignDispatcherDealResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.AssignDispatcherDealResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.AssignDispatcherDealResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.AssignDispatcherDealResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.AssignDispatcherDealResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional model.DealResponse deal = 1;
 * @return {?proto.model.DealResponse}
 */
proto.deal.AssignDispatcherDealResponse.prototype.getDeal = function() {
  return /** @type{?proto.model.DealResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DealResponse, 1));
};


/**
 * @param {?proto.model.DealResponse|undefined} value
 * @return {!proto.deal.AssignDispatcherDealResponse} returns this
*/
proto.deal.AssignDispatcherDealResponse.prototype.setDeal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.AssignDispatcherDealResponse} returns this
 */
proto.deal.AssignDispatcherDealResponse.prototype.clearDeal = function() {
  return this.setDeal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.AssignDispatcherDealResponse.prototype.hasDeal = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.SetLoadStatusDealRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.SetLoadStatusDealRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.SetLoadStatusDealRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.SetLoadStatusDealRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.SetLoadStatusDealRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    loadStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.SetLoadStatusDealRequest}
 */
proto.deal.SetLoadStatusDealRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.SetLoadStatusDealRequest;
  return proto.deal.SetLoadStatusDealRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.SetLoadStatusDealRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.SetLoadStatusDealRequest}
 */
proto.deal.SetLoadStatusDealRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {!proto.model.DealLoadStatus} */ (reader.readEnum());
      msg.setLoadStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.SetLoadStatusDealRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.SetLoadStatusDealRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.SetLoadStatusDealRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.SetLoadStatusDealRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getLoadStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal.SetLoadStatusDealRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.SetLoadStatusDealRequest} returns this
 */
proto.deal.SetLoadStatusDealRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.SetLoadStatusDealRequest} returns this
 */
proto.deal.SetLoadStatusDealRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.SetLoadStatusDealRequest} returns this
 */
proto.deal.SetLoadStatusDealRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional model.DealLoadStatus load_status = 2;
 * @return {!proto.model.DealLoadStatus}
 */
proto.deal.SetLoadStatusDealRequest.prototype.getLoadStatus = function() {
  return /** @type {!proto.model.DealLoadStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.DealLoadStatus} value
 * @return {!proto.deal.SetLoadStatusDealRequest} returns this
 */
proto.deal.SetLoadStatusDealRequest.prototype.setLoadStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.SetLoadStatusDealResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.SetLoadStatusDealResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.SetLoadStatusDealResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.SetLoadStatusDealResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deal: (f = msg.getDeal()) && protobuf_model_merged_pb.DealResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.SetLoadStatusDealResponse}
 */
proto.deal.SetLoadStatusDealResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.SetLoadStatusDealResponse;
  return proto.deal.SetLoadStatusDealResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.SetLoadStatusDealResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.SetLoadStatusDealResponse}
 */
proto.deal.SetLoadStatusDealResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.DealResponse;
      reader.readMessage(value,protobuf_model_merged_pb.DealResponse.deserializeBinaryFromReader);
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.SetLoadStatusDealResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.SetLoadStatusDealResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.SetLoadStatusDealResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.SetLoadStatusDealResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.DealResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional model.DealResponse deal = 1;
 * @return {?proto.model.DealResponse}
 */
proto.deal.SetLoadStatusDealResponse.prototype.getDeal = function() {
  return /** @type{?proto.model.DealResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DealResponse, 1));
};


/**
 * @param {?proto.model.DealResponse|undefined} value
 * @return {!proto.deal.SetLoadStatusDealResponse} returns this
*/
proto.deal.SetLoadStatusDealResponse.prototype.setDeal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.SetLoadStatusDealResponse} returns this
 */
proto.deal.SetLoadStatusDealResponse.prototype.clearDeal = function() {
  return this.setDeal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.SetLoadStatusDealResponse.prototype.hasDeal = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.GetDealsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.GetDealsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.GetDealsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.GetDealsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetDealsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.GetDealsRequest}
 */
proto.deal.GetDealsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.GetDealsRequest;
  return proto.deal.GetDealsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.GetDealsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.GetDealsRequest}
 */
proto.deal.GetDealsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.GetDealsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.GetDealsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.GetDealsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetDealsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal.GetDealsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.GetDealsRequest} returns this
 */
proto.deal.GetDealsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.GetDealsRequest} returns this
 */
proto.deal.GetDealsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.GetDealsRequest} returns this
 */
proto.deal.GetDealsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.GetDealsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.GetDealsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.GetDealsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetDealsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.model.DealResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.GetDealsResponse}
 */
proto.deal.GetDealsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.GetDealsResponse;
  return proto.deal.GetDealsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.GetDealsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.GetDealsResponse}
 */
proto.deal.GetDealsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.DealResponse.deserializeBinaryFromReader, "", new proto.model.DealResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.GetDealsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.GetDealsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.GetDealsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetDealsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.DealResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, model.DealResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.DealResponse>}
 */
proto.deal.GetDealsResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.DealResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.DealResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.deal.GetDealsResponse} returns this
 */
proto.deal.GetDealsResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.GetRouteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.GetRouteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.GetRouteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.GetRouteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetRouteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.GetRouteRequest}
 */
proto.deal.GetRouteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.GetRouteRequest;
  return proto.deal.GetRouteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.GetRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.GetRouteRequest}
 */
proto.deal.GetRouteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.GetRouteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.GetRouteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.GetRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetRouteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.deal.GetRouteRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.deal.GetRouteRequest} returns this
 */
proto.deal.GetRouteRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.deal.GetRouteRequest} returns this
 */
proto.deal.GetRouteRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.GetRouteRequest} returns this
 */
proto.deal.GetRouteRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.GetRouteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.GetRouteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.GetRouteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetRouteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.deal.RouteResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.GetRouteResponse}
 */
proto.deal.GetRouteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.GetRouteResponse;
  return proto.deal.GetRouteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.GetRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.GetRouteResponse}
 */
proto.deal.GetRouteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.deal.RouteResponse.deserializeBinaryFromReader, "", new proto.deal.RouteResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.GetRouteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.GetRouteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.GetRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.GetRouteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.deal.RouteResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, RouteResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.deal.RouteResponse>}
 */
proto.deal.GetRouteResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.deal.RouteResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.deal.RouteResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.deal.GetRouteResponse} returns this
 */
proto.deal.GetRouteResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.RouteCityGroup.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.RouteCityGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.RouteCityGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.RouteCityGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.RouteCityGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    proto.deal.RouteItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.RouteCityGroup}
 */
proto.deal.RouteCityGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.RouteCityGroup;
  return proto.deal.RouteCityGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.RouteCityGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.RouteCityGroup}
 */
proto.deal.RouteCityGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = new proto.deal.RouteItem;
      reader.readMessage(value,proto.deal.RouteItem.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.RouteCityGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.RouteCityGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.RouteCityGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.RouteCityGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.deal.RouteItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string country_name = 1;
 * @return {string}
 */
proto.deal.RouteCityGroup.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.RouteCityGroup} returns this
 */
proto.deal.RouteCityGroup.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.deal.RouteCityGroup.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.RouteCityGroup} returns this
 */
proto.deal.RouteCityGroup.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RouteItem addresses = 5;
 * @return {!Array<!proto.deal.RouteItem>}
 */
proto.deal.RouteCityGroup.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.deal.RouteItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.deal.RouteItem, 5));
};


/**
 * @param {!Array<!proto.deal.RouteItem>} value
 * @return {!proto.deal.RouteCityGroup} returns this
*/
proto.deal.RouteCityGroup.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.deal.RouteItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.deal.RouteItem}
 */
proto.deal.RouteCityGroup.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.deal.RouteItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.RouteCityGroup} returns this
 */
proto.deal.RouteCityGroup.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.RouteItem.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.RouteItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.RouteItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.RouteItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: (f = msg.getAddress()) && protobuf_model_merged_pb.AddressResponse.toObject(includeInstance, f),
    addressDetails: (f = msg.getAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.RouteItem}
 */
proto.deal.RouteItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.RouteItem;
  return proto.deal.RouteItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.RouteItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.RouteItem}
 */
proto.deal.RouteItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.AddressResponse;
      reader.readMessage(value,protobuf_model_merged_pb.AddressResponse.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 3:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setAddressDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.RouteItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.RouteItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.RouteItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.RouteItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      protobuf_model_merged_pb.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getAddressDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.deal.RouteItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.RouteItem} returns this
 */
proto.deal.RouteItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional model.AddressResponse address = 2;
 * @return {?proto.model.AddressResponse}
 */
proto.deal.RouteItem.prototype.getAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.AddressResponse, 2));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.deal.RouteItem} returns this
*/
proto.deal.RouteItem.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.RouteItem} returns this
 */
proto.deal.RouteItem.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.RouteItem.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional model.OrderAddressDetails address_details = 3;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.deal.RouteItem.prototype.getAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 3));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.deal.RouteItem} returns this
*/
proto.deal.RouteItem.prototype.setAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.deal.RouteItem} returns this
 */
proto.deal.RouteItem.prototype.clearAddressDetails = function() {
  return this.setAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.deal.RouteItem.prototype.hasAddressDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.deal.RouteResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.deal.RouteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.deal.RouteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.deal.RouteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.RouteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    routeList: jspb.Message.toObjectList(msg.getRouteList(),
    proto.deal.RouteCityGroup.toObject, includeInstance),
    cargoItemsList: jspb.Message.toObjectList(msg.getCargoItemsList(),
    protobuf_model_merged_pb.OrderItemDetailedResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.deal.RouteResponse}
 */
proto.deal.RouteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.deal.RouteResponse;
  return proto.deal.RouteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.deal.RouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.deal.RouteResponse}
 */
proto.deal.RouteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new proto.deal.RouteCityGroup;
      reader.readMessage(value,proto.deal.RouteCityGroup.deserializeBinaryFromReader);
      msg.addRoute(value);
      break;
    case 5:
      var value = new protobuf_model_merged_pb.OrderItemDetailedResponse;
      reader.readMessage(value,protobuf_model_merged_pb.OrderItemDetailedResponse.deserializeBinaryFromReader);
      msg.addCargoItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.deal.RouteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.deal.RouteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.deal.RouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.deal.RouteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRouteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.deal.RouteCityGroup.serializeBinaryToWriter
    );
  }
  f = message.getCargoItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      protobuf_model_merged_pb.OrderItemDetailedResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.deal.RouteResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.deal.RouteResponse} returns this
 */
proto.deal.RouteResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RouteCityGroup route = 4;
 * @return {!Array<!proto.deal.RouteCityGroup>}
 */
proto.deal.RouteResponse.prototype.getRouteList = function() {
  return /** @type{!Array<!proto.deal.RouteCityGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.deal.RouteCityGroup, 4));
};


/**
 * @param {!Array<!proto.deal.RouteCityGroup>} value
 * @return {!proto.deal.RouteResponse} returns this
*/
proto.deal.RouteResponse.prototype.setRouteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.deal.RouteCityGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.deal.RouteCityGroup}
 */
proto.deal.RouteResponse.prototype.addRoute = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.deal.RouteCityGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.RouteResponse} returns this
 */
proto.deal.RouteResponse.prototype.clearRouteList = function() {
  return this.setRouteList([]);
};


/**
 * repeated model.OrderItemDetailedResponse cargo_items = 5;
 * @return {!Array<!proto.model.OrderItemDetailedResponse>}
 */
proto.deal.RouteResponse.prototype.getCargoItemsList = function() {
  return /** @type{!Array<!proto.model.OrderItemDetailedResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.OrderItemDetailedResponse, 5));
};


/**
 * @param {!Array<!proto.model.OrderItemDetailedResponse>} value
 * @return {!proto.deal.RouteResponse} returns this
*/
proto.deal.RouteResponse.prototype.setCargoItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.model.OrderItemDetailedResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderItemDetailedResponse}
 */
proto.deal.RouteResponse.prototype.addCargoItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.model.OrderItemDetailedResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.deal.RouteResponse} returns this
 */
proto.deal.RouteResponse.prototype.clearCargoItemsList = function() {
  return this.setCargoItemsList([]);
};


goog.object.extend(exports, proto.deal);
